export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorMax = (value, max) => {
  if (value === undefined || value === null) return true
  return value.length <= max
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorFloat = number => {
  /* eslint-disable no-useless-escape */
  const regExp = /^-?\d*[.]?\d*$/
  /* eslint-enable no-useless-escape */
  const validFloat = regExp.test(number)
  return validFloat
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const dateValidator = (value, format) => {
  if (value === undefined || value === null) return true
  if (format === 'DD/MM/YYY') {
    const dateRegex = /^(0[0-9]|1\d|2\d|3[01])\/(0[0-9]|1[0-2])\/(18|19|20)\d{2}$/
    if (!(dateRegex.test(value))) {
      return false
    }
  }

  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) return false

  // parse the date parts to integers
  const parts = value.split('/')
  const day = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const year = parseInt(parts[2], 10)

  // check the ranges of month and year
  if (year < 1000 || year > 3000 || month > 12) return false

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  // adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29

  // check the range of the day
  if (month === 0) {
    return day >= 0 && day <= 31
  }
  return day >= 0 && day <= monthLength[month - 1]
}
